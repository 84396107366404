import Order from '@/models/Order'
import WebMessage from '@/models/WebMessage'
import router from '@/Routes'

const handleArchive = (order: Order, dataTable: any) => {
  let title = 'Archive Order'
  let message = 'Are you sure you want to archive this order? This will automatically archive & pause all line items associated with this order.'
  let okTitle = 'Archive'
  if (order.archived) {
    title = 'Unarchive Order'
    message = 'Are you sure you want to unarchive this order? You still need to manually unarchive line items associated with this order.'
    okTitle = 'Unarchive'
  }
  WebMessage.confirm(message, title, { okTitle }).then((result: boolean) => {
    if (result) {
      order.archiveOrder(!order.archived).then(() => {
        setTimeout(() => {
          dataTable.refresh()
        }, 500)
      })
    }
  })
}

export default [
  {
    key: 'checkbox',
    label: '',
    sortable: false,
    show: true,
    type: 'checkbox',
  },
  {
    key: 'name',
    label: 'Name',
    sortable: true,
    class: 'text-left align-middle',
    show: true,
    thClass: 'align-middle text-center',
  },
  {
    key: 'agency',
    label: 'Agency',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
    thClass: 'align-middle text-center',
  },
  {
    key: 'advertiser',
    label: 'Advertiser',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
    thClass: 'align-middle text-center',
  },
  {
    key: 'station',
    label: 'Station',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: false,
    thClass: 'align-middle text-center',
  },
  {
    key: 'line_item_status_count',
    label: 'Line Items',
    sortable: false,
    class: 'text-left align-middle text-capitalize',
    show: true,
    thClass: 'align-middle text-center',
  },
  {
    key: 'model',
    label: 'Model',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
    thClass: 'align-middle text-center',
    type: 'uppercase',
  },
  {
    key: 'contracted_amount',
    label: 'Contracted',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
    thClass: 'align-middle text-center',
    formatter: (value: number, _: any, order: Order) =>
      (order.open_ended ? 'Open Ended' : value.toLocaleString('en')),
  },
  {
    key: 'pacing',
    label: 'Delivered',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
    thClass: 'align-middle text-center',
  },
  {
    key: 'performance',
    label: 'Performance',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
    thClass: 'align-middle text-center',
    thStyle: 'min-width: 200px',
  },
  {
    key: 'start_at',
    label: 'Start at',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
    thClass: 'align-middle text-center',
    type: 'date',
    filter_type: 'date',
  },
  {
    key: 'end_at',
    label: 'End at',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
    thClass: 'align-middle text-center',
    type: 'date',
    filter_type: 'date',
  },
  {
    key: 'status',
    label: 'Status',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
    type: 'badge',
    thClass: 'align-middle text-center',
    formatter: (status: string, _: any, order: Order) => order.status_name,
    color: (order: Order) => order.status_color,
  },
  {
    key: 'order_issues',
    label: 'Issues',
    sortable: false,
    class: 'text-center align-middle text-capitalize',
    show: true,
    thClass: 'align-middle text-center',
  },
  {
    key: 'adserver',
    label: 'Adserver',
    sortable: false,
    class: 'text-center align-middle text-capitalize',
    show: true,
    thClass: 'align-middle text-center',
  },
  {
    key: 'history_notes_count',
    label: 'Audit notes',
    sortable: false,
    show: true,
    tdClass: 'align-middle text-center',
    type: 'badge',
    color: (order: Order) => 'info',
    thClass: 'align-middle text-center',
  },
  {
    key: 'action',
    label: '',
    sortable: false,
    show: true,
    type: 'action_list',
    tdClass: 'actions',
    thStyle: { width: '160px' },
    class: 'text-center align-middle text-capitalize',
    thClass: 'align-middle text-center',
    actions: [
      {
        icon: 'eye',
        title: 'View Order',
        event: 'view',
        action: (order: Order) => {
          if (order.adserver === 'gam') {
            window
              .open(
                `https://admanager.google.com/21789751937#delivery/order/order_overview/order_id=${order.admanager_id}&tab=line_items`,
                '_blank',
              )
              ?.focus()

            return
          }

          let ref = window.location.href.includes('legacy-dashboard') ? '&ref=LegacyDashboard' : ''
          if (order.id) {
            router.push({
              name: 'order-overview',
              params: { id: order.id },
            })
          }
        },
      },
      {
        icon: 'pencil',
        title: 'Edit Order',
        top_level: true,
        event: 'edit',
        action: (order: Order) => {
          if (order.adserver === 'gam') {
            window
              .open(
                `https://admanager.google.com/21789751937#delivery/order/order_overview/order_id=${order.admanager_id}&tab=line_items`,
                '_blank',
              )
              ?.focus()

            return
          }
          let ref = window.location.href.includes('legacy-dashboard') ? '&ref=LegacyDashboard' : ''
          if (order.id) {
            router.push({
              name: 'order',
              params: { id: order.id },
            })
          }
        },
      },
      {
        icon: 'history',
        title: 'View History',
        event: 'history',
        top_level: true,
      },
      {
        icon: 'archive',
        title: 'Archive Order',
        event: 'archive',
        show: (order: Order) => order.adserver == 'cim' && !order.archived,
        action: (order: Order, dataTable: any) => {
          handleArchive(order, dataTable)
        },
      },
      {
        icon: 'archive',
        title: 'Unarchive Order',
        event: 'unarchive',
        show: (order: Order) => order.adserver == 'cim' && order.archived,
        action: (order: Order, dataTable: any) => {
          handleArchive(order, dataTable)
        },
      },
      {
        icon: 'play',
        title: 'Play',
        event: 'play',
        show: (order: Order) => order.status === 'paused',
        action: (order: Order, dataTable: any) => {
          WebMessage.confirm(
            'Are you sure that you want to resume this order? Any paused line items will need to be resumed manually.',
            'Resume Order',
            { okTitle: 'Resume' },
          ).then((result: boolean) => {
            if (result) {
              order.play().then(() => {
                dataTable.refresh()
              })
            }
          })
        },
      },
      {
        icon: 'pause',
        title: 'Pause',
        event: 'pause',
        show: (order: Order) => order.status !== 'paused',
        action: (order: Order, dataTable: any) => {
          WebMessage.confirm(
            'Are you sure that you want to pause this order? All line items will be paused.',
            'Pause Order',
            { okTitle: 'Pause' },
          ).then((result: boolean) => {
            if (result) {
              order.pause().then(() => {
                dataTable.refresh()
              })
            }
          })
        },
      },
      {
        icon: 'sync',
        title: 'Sync Data',
        event: 'sync',
        show: (order: Order) => !!order.media_plan_id,
      },
    ],
  },
]
