
import IconAction from '@/components/IconAction/IconAction.vue'
import Widget from '@/components/Widget/Widget.vue'
import ViewModel from '@/models/ViewModel'
import {
  Component, Ref, Vue, Watch,
} from 'vue-property-decorator'
import DataTable from '@/components/DataTable/index.vue'
import SearchInput from '@/components/SearchInput/SearchInput.vue'
import Order from '@/models/Order'
import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
import moment from 'moment'
import PacingChart from '@/components/PacingChart/PacingChart.vue'
import { clone } from 'lodash'
import AuditHistory from '@/components/AuditHistory/AuditHistoryTable.vue'
import Issue from '@/models/Issue'
import WebMessage from '@/models/WebMessage'
import SyncModal from '@/components/MediaPlanItemSyncModal/SyncModal.vue'
import RowToggle from '@/components/RowToggle/RowToggle.vue'
import DatePicker from '@/components/DatePicker/DatePicker.vue'
import { currencyMask, percentageMask } from '@/models/interface/Masks'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import FormInput from '@/components/FormInput/FormInput.vue'
import OrderHomeFields from './order-home-table-fields'

@Component({
  components: {
    IconAction,
    Widget,
    DataTable,
    SearchInput,
    PacingChart,
    AuditHistory,
    SyncModal,
    RowToggle,
    DatePicker,
    SelectPicker,
    FormInput,
  },
})
export default class OrderHome extends ViewModel {
  @Ref() readonly validator!: any

  @Ref() readonly audit_history!: any

  @Ref() readonly dataTable!: HTMLFormElement

  @Ref() readonly searchInput!: SearchInput

  public show_filter_helper: boolean = false

  public page: number = 1

  public page_size: number = 25

  public loading: boolean = false

  public fields: any = OrderHomeFields

  public query: string[] = ['is_not:archived', 'adserver:cim']

  // Selected rows
  public selected: string[] = []

  public fieldFilters: any = {
    start_at: '',
    end_at: '',
  }

  public ready: boolean = false

  public records: number = 0

  public syncOrder: any = null

  public clipboard: string = ''

  public modal: any = {
    delete: false,
    history: false,
    batchUpdate: false,
  }

  public query_settings: any = {
    user_fields: [
      {
        name: 'trafficker',
        key: 'trafficker_id',
        description: 'Include only the specified trafficker',
      },
    ],
    media_plan_fields: [
      {
        name: 'media_plan',
        key: 'media_plan_id',
        description: 'Include only the specified media plan',
      },
    ],
    company_fields: [
      {
        name: 'advertiser',
        key: 'advertiser_id',
        type: 'advertiser',
        description: 'Include only the specified advertiser',
      },
      {
        name: 'agency',
        key: 'agency_id',
        type: 'agency',
        description: 'Include only the specified agency',
      },
    ],
    custom_fields: [
      {
        name: 'is:mine',
        value: 'is:mine',
        description: 'Include only the orders that belongs to you',
      },
      {
        name: 'is_not:mine',
        value: 'is_not:mine',
        description: 'Include only the orders that does not belongs to you',
      },
      {
        name: 'is:archived',
        value: 'is:archived',
        description: 'Include only archived orders',
      },
      {
        name: 'is_not:archived',
        value: 'is_not:archived',
        description: 'Include only non archived orders',
      },
      {
        name: 'adserver:cim',
        value: 'adserver:cim',
        description: 'Include only orders that are using CIM adserver',
      },
      {
        name: 'adserver:gam',
        value: 'adserver:gam',
        description: 'Include only orders that are using GAM adserver',
      },
    ],
  }

  public sortBy: string = 'created_at'

  public temp_batch_update: Order = new Order()

  public selected_to_update: string[] = []

  public filter_mode: string = 'exclusive'

  @Watch('query')
  public onQueryChange() {
    if (this.dataTable) {
      this.dataTable.page = this.page
    }
  }

  @Watch('filter_mode')
  public onFilterModeChange() {
    if (this.dataTable) {
      this.dataTable.page = this.page
    }
    this.refresh()
  }

  public get masks() {
    return { percentageMask, currencyMask }
  }

  public get chartDates() {
    let ret = []
    let date = moment().subtract(7, 'days')
    for (let i = 0; i < 7; i++) {
      ret.push(date.add(1, 'days').format('MMM, Do YYYY'))
    }

    return ret
  }

  public sortDesc: boolean = false

  public buffer_rules: any = [
    {
      name: 'None',
      value: 'none',
    },
    {
      name: 'Percentage',
      value: 'percentage',
    },
  ]

  public get show_fields() {
    return this.fields.filter((f: any) => f.show)
  }

  public isEnabled(name: string) {
    return this.selected_to_update.includes(name)
  }

  // Initialize vars
  public mounted() {
    if (!this.$route.query.length) {
      this.loadFilters()
      return
    }
    if (this.$route.query.agency) {
      this.query.push(`agency_id:${this.$route.query.agency}`)
    }

    if (this.$route.query.advertiser) {
      this.query.push(`advertiser_id:${this.$route.query.advertiser}`)
    }

    if (this.$route.query.media_plan) {
      this.query.push(`media_plan_id:${this.$route.query.media_plan}`)
    }

    this.ready = true
  }

  public normalizeName(name: string) {
    return name.replace(/_/g, ' ')
  }

  public getStatusColor(status: string) {
    let status_colors = {
      delivering: 'success',
      ready: 'info',
      paused: 'warning',
      completed: 'secondary',
      missing_creative: 'danger',
    }

    // @ts-ignore
    return status_colors[status]
  }

  public rows(ctx: any) {
    this.loading = true
    const field_filters = Object.keys(this.fieldFilters)
      .filter((key: string) => this.fieldFilters[key] !== '')
      .map((key: string) => `${key}:${this.fieldFilters[key].toLowerCase()}`)

    this.syncFilters()

    return Order.paginate({
      page_size: ctx.perPage,
      page: ctx.currentPage,
      order_by: ctx.sortBy,
      order: ctx.sortDesc ? 'desc' : 'asc',
      query: [...ctx.filter, ...field_filters],
      mode: this.filter_mode,
    }).then(result => {
      this.records = result.records
      this.selected = []
      this.loading = false
      this.ready = true
      return result.data
    })
  }

  public viewHistory(order: Order) {
    this.modal.history = true

    this.audit_history.setModel(order)

    Vue.set(this.audit_history, 'ready', true)

    Vue.set(this.audit_history, 'history_loading', false)

    this.audit_history.refresh()
  }

  public archiveOrder(order: Order, unarchive: boolean = false) {
    let state = !unarchive
    order.archiveOrder(state).then(() => {
      setTimeout(() => {
        this.refresh()
      }, 500)
    })
  }

  public refresh() {
    this.dataTable.refresh()
  }

  public resetFilters() {
    this.fieldFilters = {}
    this.query = ['is_not:archived']
    this.filter_mode = 'exclusive'
    this.clearFilters()
    this.refresh()
  }

  public syncFilters() {
    const system = getModule(SystemtModule)
    system.updateState({
      name: 'filters',
      type: 'orders',
      data: { query: this.query, fieldFilters: this.fieldFilters, mode: this.filter_mode },
    })
  }

  public loadFilters() {
    const system = getModule(SystemtModule)
    system.getFilter('orders').then((filter: any) => {
      if (filter) {
        this.query = filter.query
        this.fieldFilters = filter.fieldFilters
        this.filter_mode = filter.mode
      }
      this.ready = true
    })
  }

  public clearFilters() {
    const system = getModule(SystemtModule)
    system.updateState({
      name: 'filters',
      type: 'orders',
      data: null,
    })
  }

  public viewChildren(type: string, name: string, id: string) {
    const q = clone(this.query)

    if (q.includes(`${type}:${id}`)) {
      return
    }
    q.push(`${type}:${id}`)
    let type_name = type.replace('_id', '')
    this.searchInput.addCustomTag({
      name: `${type_name}:${name}`,
      value: `${type}:${id}`,
    })
    this.query = q
  }

  public dismissIssue(issue: any, order: Order) {
    WebMessage.confirm(
      `<strong>${order.name}</strong>. <br/> <br/>Do you want to dismiss this issue? <br/> <br/> 
      ${issue.message.replaceAll(',', '')}`,
      'Issue',
      {
        okTitle: 'Dismiss',
      },
    ).then((res: boolean) => {
      if (res) {
        Issue.dismissIssue(issue.id).then(() => {
          WebMessage.success('Issue dismissed')
          this.refresh()
        })
      }
    })
  }

  public syncOrderData(order: Order) {
    this.syncOrder = order
  }

  public resetTempBatch() {
    this.modal.batchUpdate = false
    this.selected_to_update = []
    this.selected = []
  }

  public batchUpdate() {
    this.loading = true
    this.temp_batch_update.batchUpdate(this.selected, this.selected_to_update).then(() => {
      this.loading = false
      this.resetTempBatch()
      setTimeout(() => {
        this.refresh()
      }, 200)
    })
  }

  public newBatch() {
    this.temp_batch_update = new Order()
    this.temp_batch_update.buffer_rule = 'percentage'
    this.temp_batch_update.buffer_percentage = 0
    this.selected_to_update = []
    this.modal.batchUpdate = true
  }

  public useRuleWhenSelected(key: string, rules: any) {
    if (this.isEnabled(key)) {
      return rules
    }
    return ''
  }

  public copy(val: string) {
    this.clipboard = val
    // @ts-ignore
    this.$refs.clipboard.setAttribute('type', 'text')

    setTimeout(() => {
      // @ts-ignore
      this.$refs.clipboard.select()

      let status = false

      try {
        status = document.execCommand('copy')
      } catch (e) {
        status = false
      }

      // @ts-ignore
      this.$refs.clipboard.setAttribute('type', 'hidden')

      if (status) {
        WebMessage.success('ID was copied to your clipboard!')
      } else {
        WebMessage.error(
          `Ops! We were not able to copy to your clipboard, please copy: ${this.clipboard}`,
        )
      }
    }, 100)
  }
}
